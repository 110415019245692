<template>
  <div>
    <!--    <a-row>-->
    <!--      <a-col :span="12">-->
    <!--        <a-steps v-model="current" type="navigation" size="small">-->
    <!--          <a-step title="Доставка" :status="statusFirstStep" />-->
    <!--          <a-step-->
    <!--            title="Способ оплаты"-->
    <!--            :status="statusSecondStep"-->
    <!--            :disabled="-->
    <!--              !$v.order.$dirty ||-->
    <!--                $v.order.recipient.$error ||-->
    <!--                $v.order.delivery.$error-->
    <!--            "-->
    <!--          />-->
    <!--        </a-steps>-->
    <!--      </a-col>-->
    <!--    </a-row>-->

    <div class="steps-content">
      <a-row :gutter="100">
        <a-col :span="16">
          <div>
            <!--          <div v-if="current === 0">-->
            <!--            <a-radio-group v-model="deliveryType">-->
            <!--              <a-radio-button-->
            <!--                v-for="item in deliveryTypes"-->
            <!--                :key="item.key"-->
            <!--                :value="item.key"-->
            <!--              >-->
            <!--                {{ item.name }}-->
            <!--              </a-radio-button>-->
            <!--            </a-radio-group>-->

            <h3 class="adressHeader">Контактная информация</h3>

            <a-form-item
              label="ФИО контактного лица"
              :validate-status="
                ($v.order.recipient.firstName.$error && 'error') || ''
              "
              :has-feedback="$v.order.recipient.firstName.$error"
              :help="
                $v.order.recipient.firstName.$error &&
                !$v.order.recipient.firstName.required &&
                'Необходимо заполнить ФИО контактного лица'
              "
            >
              <a-input
                :value="order.recipient.firstName"
                placeholder="Введите Имя"
                @input="
                  setClientField({
                    key: 'firstName',
                    value: $event.target.value,
                  })
                "
              />
            </a-form-item>
            <a-form-item
              label="Номер телефона"
              :validate-status="
                ($v.order.recipient.phone.$error && 'error') || ''
              "
              :has-feedback="$v.order.recipient.phone.$error"
              :help="
                $v.order.recipient.phone.$error &&
                !$v.order.recipient.phone.required &&
                'Необходимо заполнить Номер телефона'
              "
            >
              <a-input
                :value="order.recipient.phone"
                placeholder="Введите Номер телефона"
                @input="
                  setClientField({ key: 'phone', value: $event.target.value })
                "
              ></a-input>
            </a-form-item>
            <a-form-item
              label="E-mail"
              :validate-status="
                ($v.order.recipient.email.$error && 'error') || ''
              "
              :has-feedback="$v.order.recipient.email.$error"
              :help="
                $v.order.recipient.email.$error &&
                ((!$v.order.recipient.email.required &&
                  'Необходимо заполнить E-mail') ||
                  (!$v.order.recipient.email.email &&
                    'Необходимо указать корректный E-mail'))
              "
            >
              <a-input
                :value="order.recipient.email"
                placeholder="E-mail"
                @input="
                  setClientField({ key: 'email', value: $event.target.value })
                "
              ></a-input>
            </a-form-item>

            <template v-if="isDelivery">
              <div class="address adressHeader">
                <h3 class="adressHeaderTitle">Адрес доставки</h3>
              </div>
              <a-select
                style="width: 100%"
                :value="order.delivery.address"
                placeholder="Выберите Адрес доставки"
                show-search
                option-filter-prop="children"
                :options="deliveryAddresses"
                @change="addressSet($event)"
              ></a-select>
            </template>

            <template v-else>
              <h3 class="adressHeader">Ближайший пункт выдачи</h3>
              <a-input placeholder="Адрес или Индекс" />
              <a-button type="primary" class="searchBtn">
                Искать пункты выдачи
              </a-button>
            </template>

            <div v-if="basketSetCount > 1" style="margin-top: 20px">
              <h3>
                * будет создано {{ basketSetCount }} заказов, по одному на
                поставщика
              </h3>
            </div>

            <!--            <DeliveryForm />-->
          </div>
        </a-col>

        <a-col :span="8">
          <a-spin :spinning="basketLoading">
            <div>
              <div>
                <h3>Информация о заказе</h3>
                <div class="flex">
                  <p>Всего товаров:</p>
                  <p>{{ basketCount }}</p>
                </div>
                <div class="flex">
                  <h3>Итого:</h3>
                  <h3>
                    {{
                      Number(basketTotal)
                        .toFixed(2)
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
                    }}
                    р.
                  </h3>
                </div>
              </div>
              <!--              <a-input placeholder="Введите промокод" />-->
            </div>
          </a-spin>
        </a-col>
      </a-row>
    </div>

    <div class="steps-action">
      <a-button
        v-if="current > 0"
        type="primary"
        @click="submit"
        :disabled="recalculatePriceProducts.length > 0"
      >
        Готово
      </a-button>
      <a-button v-if="current > 0" style="margin-left: 8px" @click="prev">
        Назад
      </a-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"
import { required, email } from "vuelidate/lib/validators"

// import DeliveryForm from "@/components/delivery-form/DeliveryForm"

export default {
  name: "OrderRegistration",
  components: {
    // DeliveryForm,
  },
  validations() {
    return {
      order: {
        recipient: {
          firstName: {
            required,
          },
          lastName: {
            required,
          },
          phone: {
            required,
          },
          email: {
            required,
            email,
          },
        },
        delivery: {
          ...(this.deliveryType === "delivery" && {
            address: {
              required,
            },
          }),
        },
      },
    }
  },
  data() {
    return {
      current: 1,
      recalculatePriceProducts: [],
      recalculatePriceColumns: [
        {
          title: "Товар",
          dataIndex: "description",
          align: "center",
          scopedSlots: { customRender: "product" },
        },
        {
          title: "Старая цена",
          dataIndex: "calculatedPrice",
          align: "center",
          scopedSlots: { customRender: "oldPrice" },
        },
        {
          title: "Новая цена",
          dataIndex: "newPrice",
          align: "center",
          scopedSlots: { customRender: "newPrice" },
        },
      ],
    }
  },
  async mounted() {
    await this.getProducts({ reserved: true }).then(() => {
      return this.presetOrderClientFields()
    })
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      isManufacturer: "auth/isDealer",
      isSupplier: "auth/isOwner",
      isDealer: "auth/isBuyer",
      order: "orders/order",
      deliveryTypes: "orders/deliveryTypes",
      clientContacts: "basket/clientContacts",
      deliveryAddresses: "basket/deliveryAddresses",
      basketTotal: "basket/total",
      basketCount: "basket/count",
      basketSetCount: "basket/countOfBaskets",
      basketLoading: "basket/loading",
      products: "basket/products",
    }),
    deliveryType: {
      get() {
        return this.order.delivery.type
      },
      set(value) {
        this.setDeliveryField({ key: "type", value })
      },
    },
    paymentType: {
      get() {
        return this.order.payment.type
      },
      set(value) {
        this.setPaymentField({ key: "type", value })
      },
    },
    isDelivery() {
      return this.order?.delivery?.type === "delivery"
    },
  },
  methods: {
    presetOrderClientFields() {
      this.setClientField({ key: "firstName", value: this.clientContacts.name })
      this.setClientField({ key: "lastName", value: this.clientContacts.name })
      this.setClientField({ key: "phone", value: this.clientContacts.phone })
      this.setClientField({ key: "email", value: this.clientContacts.email })
      this.setDeliveryField({
        key: "address",
        value: this.deliveryAddresses[0] ? this.deliveryAddresses[0].value : "",
      })
    },

    ...mapMutations({
      setDeliveryField: "orders/SET_ORDER_DELIVERY_FIELD",
      setPaymentField: "orders/SET_ORDER_PAYMENT_FIELD",
      setClientField: "orders/SET_ORDER_CLIENT_FIELD",
      clearOrder: "orders/CLEAR_ORDER",
      clearBasket: "basket/CLEAR_BASKET",
      setProducts: "basket/SET_PRODUCTS",
    }),
    ...mapActions({
      createOrder: "orders/createOrder",
      getProducts: "basket/getProducts",
    }),
    prev() {
      if (this.isManufacturer) {
        this.$router.push(`/basketProvider`)
      } else {
        this.$router.push(`/basket`)
      }
    },
    addressSet(value) {
      this.setDeliveryField({ key: "address", value: value })
    },
    validate() {
      this.$v.$touch()

      const isValid = !this.$v.$invalid

      if (!isValid) {
        this.$message.error("Заполните все необходимые поля")
      }
      return isValid
    },
    async submit() {
      const isValid = this.validate()
      if (isValid) {
        try {
          const response = await this.createOrder()
          if (response) {
            this.$message.success("Заказ создан")
            this.clearOrder()
            this.clearBasket()
            this.$nextTick(() => {
              this.$router.push({ name: "SearchDetails" })
            })
          }
        } catch (err) {
          this.$message.error(JSON.stringify(err))
        }
      }
    },
  },
}
</script>

<style lang="scss">
.ant-form-item {
  margin-bottom: 10px !important;
}

.ant-form-item-label {
  line-height: 20px !important;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  max-width: 950px;
  padding: 20px;
}

.left-block {
  width: 60%;
}

.right-block {
  width: 30%;
}

.steps-action {
  margin-top: 24px;
}

.address {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.adressHeader {
  margin-top: 25px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}

.adressHeaderTitle {
  margin-bottom: 0;
}

.searchBtn {
  margin-top: 10px;
  margin-bottom: 20px;
}

.payBtn {
  margin-top: 10px;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cardDateFields {
  width: 20% !important;
}

.little-imputs {
  width: 100px !important;
}

.clients-list,
.dealers-list {
  margin: 15px 0;
}
</style>
